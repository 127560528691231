.checklistStatus {
  display:flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  width: 2rem;
  height: 2rem;
  border: 2px #000000 solid;
  border-radius: 0.5rem;
  color: #000000;
  --color-pending: orange;
  --color-archived: grey;
  --color-completed: green;
  --color-expired: red;
  &:before{
    content: '-';
  }
  &.pending {
    color: var(--color-pending);
    border-color: var(--color-pending);
    &:before{
      content: 'P';
    }
  }

  &.archived {
    color: var(--color-archived);
    border-color: var(--color-archived);
    &:before{
      content: 'A';
    }
  }

  &.completed {
    color: var(--color-completed);
    border-color: var(--color-completed);
    &:before{
      content: 'C';
    }
  }

  &.expired {
    color: var(--color-expired);
    border-color: var(--color-expired);
    &:before{
      content: 'P';
    }
  }
}

