html, body, #root {
  position: relative;
  height: 100%;
}

html {
  font-size: 17px;
}
.p-toast {
  width: initial;
  font-size: 0.8rem;
}
