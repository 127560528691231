.SwipeRowContainer {
  touch-action: none;
  main {
    transition: left 0.2s;

    aside {
      top: 0;
    }

    aside:nth-child(1) {
      right: 100%;
    }

    aside:nth-child(3), aside:nth-child(2) {
      left: 100%;
    }
  }

}
