.InputInlineContainer {
  input {
    border: none;
    background: transparent;
    width: 100%;
    &:focus-visible, &:active, &:focus {
      border-bottom: 2px solid gray;
      outline: initial;
    }
  }
  button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: orange;
  }
}
